import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userTweet: [],

  promoteBanner: [],
  promotePress: [],
  largestPriceIncrease: [],
  singleCoinDetail: [],
  getWatchList: [],
  marketCap: null,
  TopCoins: [],
  holderData: [],
  voteHistory: []

};
export const CoinDetails = createSlice({
  name: "CoinDetail",
  initialState,
  reducers: {
    tweetData(state, action) {
      state.userTweet = action.payload;
    },

    largestPrice(state, action) {
      state.largestPriceIncrease = action.payload;
    },

    saveSingleCoinDetail(state, action) {
      state.singleCoinDetail = action.payload;
    },
    promotedBanner(state, action) {
      state.promoteBanner = action.payload;
    },
    promotedPress(state, action) {
      state.promotePress = action.payload;
    },
    getWatchListData(state, action) {
      state.getWatchList = action.payload;
    },
    saveMarketCap(state, action) {
      state.marketCap = action.payload;
    },
    saveTopCoins(state, action) {
      state.TopCoins = action.payload;
    },
    saveHolders(state, action) {
      state.holderData = action.payload;
    },
    saveVoteHistoryDetail(state, action) {
      state.voteHistory = action.payload;
    }

  },
});
export const {
  tweetData,
  promotedCoin,
  largestPrice,
  promotedBanner,
  promotedPress,
  saveSingleCoinDetail,
  getWatchListData,
  saveMarketCap,
  saveTopCoins,
  saveHolders,
  saveVoteHistoryDetail
} = CoinDetails.actions;
export default CoinDetails.reducer;
