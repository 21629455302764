import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  unstakeContract: {
    stats:{
      stake:0,
      reward:0
    },
    instances:[]
  }
};
export const Staking = createSlice({
  name: "unstake",
  initialState,
  reducers: {
    
    saveUnstakeContract( state, action ) {
      state.unstakeContract = action.payload;
    }
  },
});
export const { saveUnstakeContract } = Staking.actions;
export default Staking.reducer;
